import stateCatalogue from '@/services/port-cdm/stateCatalogue';
import formatPortcallStateDefinitions from '@/helpers/formatPortcallStateDefinitions.helper';

export const portCallDefinition = {
    state: {
        statesDefinitions: [],
    },
    getters: {
        statesDefinitions: state => {
            return state.statesDefinitions;
        },
    },
    mutations: {
        setStatesDefinitions(state, statesDefinitions) {
            state.statesDefinitions = statesDefinitions;
        },
    },
    actions: {
        async getStatesDefinitions({ commit }) {
            const response = await stateCatalogue.index();
            const statesDefinitions = formatPortcallStateDefinitions(response.data);

            commit('setStatesDefinitions', statesDefinitions);
        },
    },
};
