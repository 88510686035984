<template>
    <div
        :class="[calendarClass, 'mf-datepicker__calendar', 'mf-datepicker__month']"
        v-show="showMonthView"
        :style="calendarStyle"
        @mousedown.prevent
        :key="Date.parse(pageDate)"
    >
        <div class="mf-datepicker__scroll" ref="month-scroll" @scroll.prevent="handleMonthScroll">
            <template v-for="year in years">
                <header class="month-picker__header" :key="year.timestamp">
                    {{ year.year }}
                </header>
                <span
                    class="cell-container cell-container_month"
                    v-for="month in monthsOfYear(year)"
                    :key="year.timestamp + month.timestamp"
                >
                    <span
                        class="cell month"
                        :class="{ selected: month.isSelected, disabled: month.isDisabled }"
                        @click.stop="selectMonth(month)"
                    >
                        {{ month.timestamp | shortMonthLabel }}
                    </span>
                </span>
            </template>
        </div>
    </div>
</template>
<script>
    import PickerMonth from 'vuejs-datepicker/src/components/PickerMonth';
    export default {
        extends: PickerMonth,
        computed: {
            years() {
                const d = this.pageDate;
                let years = [];
                // set up a new date object to the beginning of the current 'page'7
                let dObj = this.useUtc
                    ? new Date(Date.UTC(Math.floor(d.getUTCFullYear() / 10) * 10, d.getUTCMonth(), d.getUTCDate()))
                    : new Date(
                          Math.floor(d.getFullYear() / 10) * 10,
                          d.getMonth(),
                          d.getDate(),
                          d.getHours(),
                          d.getMinutes()
                      );
                for (let i = 0; i < 10; i++) {
                    years.push({
                        year: this.utils.getFullYear(dObj),
                        timestamp: dObj.getTime(),
                        isSelected: this.isSelectedYear(dObj),
                        isDisabled: this.isDisabledYear(dObj),
                    });
                    this.utils.setFullYear(dObj, this.utils.getFullYear(dObj) - 1);
                }
                return years;
            },
        },
        methods: {
            handleMonthScroll() {
                const element = this.$refs['month-scroll'];

                const bottomOfMonths = element.scrollTop + element.clientHeight >= element.scrollHeight;
                const headerOfMonths = element.scrollTop <= 0;

                if (bottomOfMonths) {
                    this.previousDecade();
                }
                if (headerOfMonths) {
                    this.nextDecade();
                    this.$nextTick(this.scrollToLastDisplayedYear);
                }
            },
            scrollToLastDisplayedYear() {
                const element = this.$refs['month-scroll'];
                element.scrollTop = element.scrollHeight - element.clientHeight - 1;
            },
            changeYear(incrementBy) {
                let date = this.pageDate;
                this.utils.setFullYear(date, this.utils.getFullYear(date) + incrementBy);
                this.$emit('changedDecade', date);
            },
            previousDecade() {
                if (this.isPreviousDecadeDisabled()) {
                    return false;
                }
                this.changeYear(-10);
            },
            isPreviousDecadeDisabled() {
                if (!this.disabledDates || !this.disabledDates.to) {
                    return false;
                }
                const disabledYear = this.utils.getFullYear(this.disabledDates.to);
                const lastYearInPreviousPage = Math.floor(this.utils.getFullYear(this.pageDate) / 10) * 10 - 1;
                return disabledYear > lastYearInPreviousPage;
            },
            nextDecade() {
                if (this.isNextDecadeDisabled()) {
                    return false;
                }
                this.changeYear(10);
            },
            isNextDecadeDisabled() {
                if (!this.disabledDates || !this.disabledDates.from) {
                    return false;
                }
                const disabledYear = this.utils.getFullYear(this.disabledDates.from);
                const firstYearInNextPage = Math.ceil(this.utils.getFullYear(this.pageDate) / 10) * 10;
                return disabledYear < firstYearInNextPage;
            },
            monthsOfYear(year) {
                const d = new Date(year.timestamp);
                let months = [];
                // set up a new date object to the beginning of the current 'page'
                let dObj = this.useUtc
                    ? new Date(Date.UTC(d.getUTCFullYear(), 0, d.getUTCDate()))
                    : new Date(d.getFullYear(), 0, d.getDate(), d.getHours(), d.getMinutes());
                for (let i = 0; i < 12; i++) {
                    months.push({
                        month: this.utils.getMonthName(i, this.translation.months),
                        timestamp: dObj.getTime(),
                        isSelected: this.isSelectedMonth(dObj),
                        isDisabled: this.isDisabledMonth(dObj),
                    });
                    this.utils.setMonth(dObj, this.utils.getMonth(dObj) + 1);
                }
                return months;
            },
            selectMonth(month) {
                if (month.isDisabled) {
                    return false;
                }
                this.$emit('selectMonth', month);
            },
            selectYear(year) {
                if (year.isDisabled) {
                    return false;
                }
                this.$emit('selectYear', year);
            },
            isSelectedYear(date) {
                return this.selectedDate && this.utils.getFullYear(this.selectedDate) === this.utils.getFullYear(date);
            },
            isDisabledYear(date) {
                let disabledDates = false;
                if (typeof this.disabledDates === 'undefined' || !this.disabledDates) {
                    return false;
                }

                if (typeof this.disabledDates.to !== 'undefined' && this.disabledDates.to) {
                    if (this.utils.getFullYear(date) < this.utils.getFullYear(this.disabledDates.to)) {
                        disabledDates = true;
                    }
                }
                if (typeof this.disabledDates.from !== 'undefined' && this.disabledDates.from) {
                    if (this.utils.getFullYear(date) > this.utils.getFullYear(this.disabledDates.from)) {
                        disabledDates = true;
                    }
                }

                if (
                    typeof this.disabledDates.customPredictor === 'function' &&
                    this.disabledDates.customPredictor(date)
                ) {
                    disabledDates = true;
                }

                return disabledDates;
            },
        },
    };
</script>
