export default function transformRequestParams(params) {
    let urlParams = new URLSearchParams();
    params instanceof Object
        ? Object.keys(params).forEach(key => {
              const value = params[key];
              value instanceof Array
                  ? value.forEach(innerValue => {
                        urlParams.append(key, innerValue);
                    })
                  : urlParams.append(key, value);
          })
        : '';
    return urlParams.toString();
}
