<template>
    <div class="table-responsive-container">
        <div
            class="table-responsive"
            @mousedown="handleMouseDown"
            @mousemove="handleMouseMove"
            @mouseup="disableScroll"
            @mouseleave="disableScroll"
            ref="table"
        >
            <slot />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MfResponsiveTable',
        methods: {
            handleMouseDown(event) {
                const element = this.$refs.table;
                element.style.cursor = 'grabbing';
                element.style.userSelect = 'none';
                this.startX = event.pageX - event.target.offsetLeft;
                this.isAbleToScroll = true;
            },
            handleMouseMove(event) {
                if (!this.isAbleToScroll) {
                    return;
                }
                event.preventDefault();
                const x = event.pageX - event.target.offsetLeft;
                const walk = x - this.startX;
                this.$refs.table.scrollLeft -= walk;
            },
            disableScroll() {
                const element = this.$refs.table;
                element.style.cursor = 'grab';
                element.style.removeProperty('user-select');
                this.isAbleToScroll = false;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .table-responsive-container {
        display: grid;
    }

    .table-responsive {
        overflow-x: auto;

        @include scrollbar();
    }
</style>
