import vesselTypesVR from '@/services/vessel-registry/vesselTypesVR';
import capitalize from '@/filters/capitalize.filter';

export const vesselTypes = {
    state: {
        vesselTypes: [],
    },
    getters: {
        vesselTypes: state => {
            return state.vesselTypes;
        },
    },
    mutations: {
        setVesselTypes(state, vesselTypes) {
            state.vesselTypes = vesselTypes;
        },
    },
    actions: {
        async getVesselTypes({ commit }) {
            const response = await vesselTypesVR.index();
            const vesselTypes = response.data.map(value =>
                value
                    .toLowerCase()
                    .split('_')
                    .map(capitalize)
                    .join(' ')
            );
            commit('setVesselTypes', vesselTypes);
        },
    },
};
