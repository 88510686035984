<template>
    <label class="mf-radio" :class="{ 'mf-radio_disabled': disabled, 'mf-radio_checked': checked }">
        <input class="mf-radio__input" type="radio" @change="input" :checked="checked" />
        <div class="mf-radio__box">
            <div class="mf-radio__icon" />
        </div>
        <div class="mf-radio__text">
            <slot />
        </div>
    </label>
</template>

<script>
    export default {
        name: 'MfRadio',
        props: {
            checked: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        methods: {
            input(event) {
                this.$emit('change', event.target.checked);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .mf-radio {
        color: $white;
        display: flex;
        cursor: pointer;

        &:hover {
            .mf-radio__box {
                border-color: $blue-300;
            }
        }
    }

    .mf-radio__input {
        display: none;
    }

    .mf-radio__box {
        width: 28px;
        height: 28px;
        border: 2px solid $marine-400;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px;
    }

    .mf-radio__icon {
        display: none;
        background: $blue-300;
        border-radius: 50%;
        height: 100%;
        width: 100%;
    }

    .mf-radio__text {
        display: flex;
        align-items: center;
        padding-left: 10px;
        flex: 1 1 auto;
    }

    .mf-radio_checked {
        .mf-radio__box {
            border-color: $blue-300;
        }

        .mf-radio__icon {
            display: block;
        }
    }

    .mf-radio_disabled {
        pointer-events: none;

        .mf-radio__box {
            border-color: $marine-500;
        }

        .mf-radio__text {
            color: $marine-500;
        }
    }
</style>
