import { Api } from '@/services/apiService';

const formatString = string => {
    let output = '';
    for (let i = 0; i < string.length; i++) {
        if (string.charCodeAt(i) <= 127) {
            output += string.charAt(i);
        }
    }
    return output;
};

class vesselVR {
    static show(id) {
        const vesselId = formatString(id);
        return Api.get(`/vr/vessel/${vesselId}`);
    }
}

export default vesselVR;
