<template>
    <div class="mf-datepicker" :class="[wrapperClass, isRtl ? 'rtl' : '']" v-on-clickaway="close">
        <date-input
            :selectedDate="selectedDate"
            :resetTypedDate="resetTypedDate"
            :format="format"
            :translation="translation"
            :inline="inline"
            :id="id"
            :name="name"
            :refName="refName"
            :openDate="openDate"
            :placeholder="placeholder"
            :inputClass="inputClass"
            :typeable="typeable"
            :clearButton="clearButton"
            :clearButtonIcon="clearButtonIcon"
            :calendarButton="calendarButton"
            :calendarButtonIcon="calendarButtonIcon"
            :calendarButtonIconContent="calendarButtonIconContent"
            :disabled="disabled"
            :required="required"
            :bootstrapStyling="bootstrapStyling"
            :use-utc="useUtc"
            @showCalendar="showCalendar"
            @closeCalendar="close"
            @typedDate="setTypedDate"
            @clearDate="clearDate"
        >
            <slot name="afterDateInput" slot="afterDateInput"></slot>
        </date-input>

        <!-- Day View -->
        <picker-day
            v-if="allowedToShowView('day')"
            :pageDate="pageDate"
            :selectedDate="selectedDate"
            :showDayView="showDayView"
            :fullMonthName="fullMonthName"
            :allowedToShowView="allowedToShowView"
            :disabledDates="disabledDates"
            :highlighted="highlighted"
            :calendarClass="calendarClass"
            :calendarStyle="calendarStyle"
            :translation="translation"
            :pageTimestamp="pageTimestamp"
            :isRtl="isRtl"
            :mondayFirst="mondayFirst"
            :dayCellContent="dayCellContent"
            :use-utc="useUtc"
            @changedMonth="handleChangedMonthFromDayPicker"
            @selectDate="selectDate"
            @showMonthCalendar="showMonthCalendar"
            @selectedDisabled="selectDisabledDate"
        >
            <slot name="beforeCalendarHeader" slot="beforeCalendarHeader"></slot>
        </picker-day>

        <!-- Month View -->
        <picker-month
            v-if="allowedToShowView('month')"
            :pageDate="pageDate"
            :selectedDate="selectedDate"
            :showMonthView="showMonthView"
            :allowedToShowView="allowedToShowView"
            :disabledDates="disabledDates"
            :calendarClass="calendarClass"
            :calendarStyle="calendarStyle"
            :translation="translation"
            :isRtl="isRtl"
            :use-utc="useUtc"
            @selectMonth="selectMonth"
            @showYearCalendar="showYearCalendar"
            @changedYear="setPageDate"
            @selectYear="selectYear"
            @changedDecade="setPageDate"
        >
            <slot name="beforeCalendarHeader" slot="beforeCalendarHeader"></slot>
        </picker-month>
    </div>
</template>
<script>
    import en from 'vuejs-datepicker/src/locale/translations/en';
    import DateInput from '../datepicker-components/DateInput.vue';
    import PickerDay from '../datepicker-components/PickerDay.vue';
    import PickerMonth from '../datepicker-components/PickerMonth.vue';
    import utils, { makeDateUtils } from 'vuejs-datepicker/src/utils/DateUtils';
    import { mixin as clickaway } from 'vue-clickaway';
    export default {
        components: {
            DateInput,
            PickerDay,
            PickerMonth,
        },
        mixins: [clickaway],
        props: {
            value: {
                validator: val => utils.validateDateInput(val),
            },
            name: String,
            refName: String,
            id: String,
            format: {
                type: [String, Function],
                default: 'yyyy-MM-dd',
            },
            language: {
                type: Object,
                default: () => en,
            },
            openDate: {
                validator: val => utils.validateDateInput(val),
            },
            dayCellContent: Function,
            fullMonthName: Boolean,
            disabledDates: Object,
            highlighted: Object,
            placeholder: String,
            inline: Boolean,
            calendarClass: [String, Object, Array],
            inputClass: [String, Object, Array],
            wrapperClass: [String, Object, Array],
            mondayFirst: Boolean,
            clearButton: Boolean,
            clearButtonIcon: String,
            calendarButton: Boolean,
            calendarButtonIcon: String,
            calendarButtonIconContent: String,
            bootstrapStyling: Boolean,
            initialView: String,
            disabled: Boolean,
            required: Boolean,
            typeable: {
                type: Boolean,
                default: false,
            },
            useUtc: Boolean,
            minimumView: {
                type: String,
                default: 'day',
            },
            maximumView: {
                type: String,
                default: 'year',
            },
        },
        data() {
            const startDate = this.openDate ? new Date(this.openDate) : new Date();
            const constructedDateUtils = makeDateUtils(this.useUtc);
            const pageTimestamp = constructedDateUtils.setDate(startDate, 1);
            return {
                /*
                 * Vue cannot observe changes to a Date Object so date must be stored as a timestamp
                 * This represents the first day of the current viewing month
                 * {Number}
                 */
                pageTimestamp,
                /*
                 * Selected Date
                 * {Date}
                 */
                selectedDate: null,
                /*
                 * Flags to show calendar views
                 * {Boolean}
                 */
                showDayView: false,
                showMonthView: false,
                showYearView: false,
                /*
                 * Positioning
                 */
                calendarHeight: 0,
                resetTypedDate: new Date(),
                utils: constructedDateUtils,
            };
        },
        watch: {
            value(value) {
                this.setValue(value);
            },
            openDate() {
                this.setPageDate();
            },
            initialView() {
                this.setInitialView();
            },
        },
        computed: {
            computedInitialView() {
                if (!this.initialView) {
                    return this.minimumView;
                }

                return this.initialView;
            },
            pageDate() {
                return new Date(this.pageTimestamp);
            },

            translation() {
                return this.language;
            },
            calendarStyle() {
                return {
                    position: this.isInline ? 'static' : undefined,
                };
            },
            isOpen() {
                return this.showDayView || this.showMonthView || this.showYearView;
            },
            isInline() {
                return !!this.inline;
            },
            isRtl() {
                return this.translation.rtl === true;
            },
        },
        methods: {
            /**
             * Called in the event that the user navigates to date pages and
             * closes the picker without selecting a date.
             */
            resetDefaultPageDate() {
                if (this.selectedDate === null) {
                    this.setPageDate();
                    return;
                }
                this.setPageDate(this.selectedDate);
            },
            /**
             * Effectively a toggle to show/hide the calendar
             * @return {mixed}
             */
            showCalendar() {
                if (this.disabled || this.isInline) {
                    return false;
                }
                if (this.isOpen) {
                    return this.close(true);
                }
                this.setInitialView();
            },
            /**
             * Sets the initial picker page view: day, month or year
             */
            setInitialView() {
                const initialView = this.computedInitialView;
                if (!this.allowedToShowView(initialView)) {
                    throw new Error(
                        `initialView '${this.initialView}' cannot be rendered based on minimum '${this.minimumView}' and maximum '${this.maximumView}'`
                    );
                }
                switch (initialView) {
                    case 'year':
                        this.showYearCalendar();
                        break;
                    case 'month':
                        this.showMonthCalendar();
                        break;
                    default:
                        this.showDayCalendar();
                        break;
                }
            },
            /**
             * Are we allowed to show a specific picker view?
             * @param {String} view
             * @return {Boolean}
             */
            allowedToShowView(view) {
                const views = ['day', 'month', 'year'];
                const minimumViewIndex = views.indexOf(this.minimumView);
                const maximumViewIndex = views.indexOf(this.maximumView);
                const viewIndex = views.indexOf(view);

                return viewIndex >= minimumViewIndex && viewIndex <= maximumViewIndex;
            },
            /**
             * Show the day picker
             * @return {Boolean}
             */
            showDayCalendar() {
                if (!this.allowedToShowView('day')) {
                    return false;
                }
                this.close();
                this.showDayView = true;
                return true;
            },
            /**
             * Show the month picker
             * @return {Boolean}
             */
            showMonthCalendar() {
                if (!this.allowedToShowView('month')) {
                    return false;
                }
                this.close();
                this.showMonthView = true;
                return true;
            },
            /**
             * Show the year picker
             * @return {Boolean}
             */
            showYearCalendar() {
                if (!this.allowedToShowView('year')) {
                    return false;
                }
                this.close();
                this.showYearView = true;
                return true;
            },
            /**
             * Set the selected date
             * @param {Number} timestamp
             */
            setDate(timestamp) {
                const date = new Date(timestamp);
                this.selectedDate = date;
                this.setPageDate(date);
                this.$emit('selected', date);
                this.$emit('input', date);
            },
            /**
             * Clear the selected date
             */
            clearDate() {
                this.selectedDate = null;
                this.setPageDate();
                this.$emit('selected', null);
                this.$emit('input', null);
                this.$emit('cleared');
            },
            /**
             * @param {Object} date
             */
            selectDate(date) {
                this.setDate(date.timestamp);
                if (!this.isInline) {
                    this.close(true);
                }
                this.resetTypedDate = new Date();
            },
            /**
             * @param {Object} date
             */
            selectDisabledDate(date) {
                this.$emit('selectedDisabled', date);
            },
            /**
             * @param {Object} month
             */
            selectMonth(month) {
                const date = new Date(month.timestamp);
                if (this.allowedToShowView('day')) {
                    this.setPageDate(date);
                    this.$emit('changedMonth', month);
                    this.showDayCalendar();
                } else {
                    this.selectDate(month);
                }
            },
            /**
             * @param {Object} year
             */
            selectYear(year) {
                const date = new Date(year.timestamp);
                if (this.allowedToShowView('month')) {
                    this.setPageDate(date);
                    this.$emit('changedYear', year);
                    this.showMonthCalendar();
                } else {
                    this.selectDate(year);
                }
            },
            /**
             * Set the datepicker value
             * @param {Date|String|Number|null} date
             */
            setValue(date) {
                if (typeof date === 'string' || typeof date === 'number') {
                    let parsed = new Date(date);
                    date = isNaN(parsed.valueOf()) ? null : parsed;
                }
                if (!date) {
                    this.setPageDate();
                    this.selectedDate = null;
                    return;
                }
                this.selectedDate = date;
                this.setPageDate(date);
            },
            /**
             * Sets the date that the calendar should open on
             */
            setPageDate(date) {
                if (!date) {
                    if (this.openDate) {
                        date = new Date(this.openDate);
                    } else {
                        date = new Date();
                    }
                }
                this.pageTimestamp = this.utils.setDate(new Date(date), 1);
            },
            /**
             * Handles a month change from the day picker
             */
            handleChangedMonthFromDayPicker(date) {
                this.setPageDate(date);
                this.$emit('changedMonth', date);
            },
            /**
             * Set the date from a typedDate event
             */
            setTypedDate(date) {
                this.setDate(date.getTime());
            },
            /**
             * Close all calendar layers
             * @param {Boolean} emitEvent - emit close event
             */
            close(emitEvent) {
                this.showDayView = this.showMonthView = this.showYearView = false;
                if (!this.isInline) {
                    if (emitEvent) {
                        this.$emit('closed');
                    }
                    document.removeEventListener('click', this.clickOutside, false);
                }
            },
            /**
             * Initiate the component
             */
            init() {
                if (this.value) {
                    this.setValue(this.value);
                }
                if (this.isInline) {
                    this.setInitialView();
                }
            },
        },
        mounted() {
            this.init();
        },
    };
</script>

<style lang="scss" scoped>
    .rtl {
        direction: rtl;
    }

    .mf-datepicker {
        position: relative;
        text-align: left;

        * {
            box-sizing: border-box;
        }
    }

    /deep/ {
        .mf-datepicker__calendar {
            position: absolute;
            left: 0;
            z-index: 100;
            width: 260px;
            padding: $space-16;

            background-color: $marine-700;
            border-radius: 6px;
            border: none;

            @include media($sm) {
                width: 360px;
            }

            header {
                font-weight: bold;
                font-size: $font-18;
                line-height: $font-24;

                display: flex;
                justify-content: space-between;
                align-items: center;
                text-align: center;

                color: $white;
                margin-bottom: $space-16;

                span {
                    display: inline-block;
                    text-align: center;
                }

                .prev,
                .next {
                    color: $marine-400;
                    &.disabled {
                        opacity: 0.3;
                        pointer-events: none;
                    }
                }

                .prev {
                    margin-left: $space-16;
                }

                .next {
                    margin-right: $space-16;
                }

                .prev:not(.disabled),
                .next:not(.disabled),
                .up:not(.disabled) {
                    cursor: pointer;

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }

            .flex-rtl {
                display: flex;
                width: inherit;
                flex-wrap: wrap;
            }

            .main-area {
                display: flex;
                width: inherit;
                margin: (-$space-4);
                flex-wrap: wrap;
            }

            .cell-container {
                padding: $space-4;
                width: 40px;
                height: 30px;
                display: inline-block;
                text-align: center;
                vertical-align: middle;
                box-sizing: content-box;
            }

            .cell-container_month {
                width: 45px;
            }

            .cell {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                font-family: 'Proxima Nova', serif;
                font-weight: normal;
                font-size: $font-14;
                line-height: $font-16;

                color: $white;

                &:not(.blank):not(.disabled).day,
                &:not(.blank):not(.disabled).month,
                &:not(.blank):not(.disabled).year {
                    cursor: pointer;

                    &:hover {
                        background: $marine-400;
                        border-radius: 4px;
                    }
                }

                &.selected {
                    color: $blue-300;

                    &:hover {
                        color: $blue-300;
                    }

                    &.highlighted {
                        color: $blue-300;
                    }
                }

                &.highlighted {
                    //background: #cae5ed;
                    background: $marine-600;
                    margin: 0 (-$space-4);

                    &.disabled {
                        color: #a3a3a3;
                    }
                }

                &.highlight-start,
                &.highlight-end {
                    color: $blue-300;
                }

                &.highlight-start {
                    border-radius: $space-4 0 0 $space-4;
                }

                &.highlight-end {
                    border-radius: 0 $space-4 $space-4 0;
                }

                &.sat {
                    margin: 0 0 0 (-$space-4);
                }

                &.sun {
                    margin: 0 (-$space-4) 0 0;
                }

                &.blank {
                    color: $marine-400;
                }

                &.day-header {
                    font-family: 'Proxima Nova', serif;
                    font-size: $font-14;
                    line-height: $font-16;

                    color: $marine-400;

                    white-space: nowrap;
                    cursor: inherit;

                    &:hover {
                        background: inherit;
                    }
                }
            }

            .disabled {
                color: #aaa;
                cursor: default;
            }

            .month-picker__header {
                justify-content: center;
                margin-bottom: $space-4;
            }

            .month-picker__header:not(:first-child) {
                margin-top: $space-16;
            }

            .mf-datepicker__clear-button,
            .mf-datepicker__calendar-button {
                cursor: pointer;
                font-style: normal;

                &.disabled {
                    color: #999;
                    cursor: default;
                }
            }
        }

        .mf-datepicker__calendar_right {
            right: 0;
            left: unset;
        }

        .mf-datepicker__month {
            overflow: hidden;
            max-height: 282px;
        }

        .mf-datepicker__scroll {
            overflow-x: hidden;
            overflow-y: scroll;
            height: 100%;
            max-height: 250px;
            @include scrollbar();
        }
    }
</style>
