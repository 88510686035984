import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

require('./build/defaultComponents.build');
require('./build/defaultFilters.build');
require('./build/veeValidate.build');
require('./build/vueHighcharts.build');
require('./build/googleMap.build');
require('./build/vueToasted.build');
require('./build/laravelEcho.build');

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
