import { render, staticRenderFns } from "./MfBox.vue?vue&type=template&id=032b26c2&scoped=true&"
import script from "./MfBox.vue?vue&type=script&lang=js&"
export * from "./MfBox.vue?vue&type=script&lang=js&"
import style0 from "./MfBox.vue?vue&type=style&index=0&id=032b26c2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "032b26c2",
  null
  
)

export default component.exports