export const sidebar = {
    state: {
        sidebarValue: true,
    },
    getters: {
        sidebarValue: state => {
            return state.sidebarValue;
        },
    },
    mutations: {
        setSidebarValue(state, value) {
            state.sidebarValue = value;
        },
    },
};
