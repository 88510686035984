import capitalize from '@/filters/capitalize.filter';
import dateFilter from '@/filters/date.filter';
import { cloneDeep } from 'lodash';

const statisticObject = {
    overallStage: {
        PLANNED: 0,
        ARRIVED: 0,
        ANCHORED: 0,
        BERTHED: 0,
        UNDER_WAY: 0,
        SAILED: 0,
    },
    overallStatus: {
        OK: 0,
        WARNING: 0,
        CRITICAL: 0,
        EMPTY: 0,
        UNKNOWN: 0,
    },
    overallWorkload: {},
};

function transformObjectToObjectsArray(object, isWorkloadObject = false) {
    return Object.keys(object).map(key => ({
        name: isWorkloadObject
            ? dateFilter(new Date(+key), 'statistic')
            : key
                  .split('_')
                  .map(value => capitalize(value.toLowerCase()))
                  .join(' '),
        y: object[key],
    }));
}

function addDays(date, days) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

function isSameDayInParsedData(date1, date2) {
    date1 = new Date(date1);
    date2 = new Date(date2);
    return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
    );
}

export default async function formatPortStatistic(portcalls, filterTime) {
    let statistic = cloneDeep(statisticObject);

    const currentDate = new Date(filterTime.after);
    const timeDifference = Math.floor(
        (filterTime.before.getTime() - filterTime.after.getTime()) / (1000 * 60 * 60 * 24)
    );

    for (let i = 0; i <= timeDifference; i++) {
        statistic.overallWorkload[Date.parse(addDays(currentDate, i))] = 0;
    }
    portcalls.forEach(portcall => {
        statistic.overallStage[portcall.stage] += 1;
        statistic.overallStatus[portcall.status] += 1;
        Object.keys(statistic.overallWorkload).forEach(key => {
            // TODO: CHECK LOGIC OF VESSEL ADD
            if (isSameDayInParsedData(Date.parse(portcall.endTime), +key)) {
                statistic.overallWorkload[key] += 1;
            }
        });
    });
    statistic.overallStage = transformObjectToObjectsArray(statistic.overallStage);
    statistic.overallStatus = transformObjectToObjectsArray(statistic.overallStatus);
    statistic.overallWorkload = transformObjectToObjectsArray(statistic.overallWorkload, true);
    return statistic;
}
