import { Api } from '@/services/apiService';
import transformRequestParams from '@/helpers/transformRequestParams.helper';

class locationLR {
    static index(params) {
        return Api.get(`/location-registry/locations?${transformRequestParams(params)}`);
    }

    static show(id) {
        // ALWAYS RETURN SAME LOCATION
        return Api.get(`/location-registry/location/${id}`);
    }
}

export default locationLR;
