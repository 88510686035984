<template>
    <div class="dropdown" v-on-clickaway="close">
        <div class="dropdown__link" :class="{ dropdown__link_disable: disable }" @click="toggle">
            <slot name="link"></slot>
            <div class="dropdown__icon" v-if="!withoutIcon">
                <mf-icon icon-name="icon-down" height="12" width="12"></mf-icon>
            </div>
        </div>
        <div
            class="dropdown__container"
            :class="{
                dropdown__container_open: isOpen,
                dropdown__container_right: openFromRight,
                'dropdown__container_with-top-space': withTopSpace,
            }"
        >
            <slot></slot>
        </div>
    </div>
</template>

<script>
    import { mixin as clickaway } from 'vue-clickaway';

    export default {
        mixins: [clickaway],
        props: {
            openFromRight: {
                type: Boolean,
                default: true,
            },
            withoutIcon: {
                type: Boolean,
                default: false,
            },
            withTopSpace: {
                type: Boolean,
                default: false,
            },
            disable: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                isOpen: false,
            };
        },
        methods: {
            toggle() {
                if (!this.disable) {
                    this.isOpen = !this.isOpen;
                }
            },
            close() {
                this.isOpen = false;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .dropdown {
        position: relative;
    }

    .dropdown__link {
        display: flex;
        align-items: center;
        color: $white;
        font-weight: normal;
        font-size: $font-18;
        line-height: $font-24;
        position: relative;
        cursor: pointer;
    }

    .dropdown__link_disable {
        opacity: 0.5;
    }

    .dropdown__icon {
        padding-left: $space-8;
    }

    .dropdown__container {
        position: absolute;
        min-width: 206px;
        background: $marine-700;
        border-radius: $border-radius-4;
        top: -12px;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.5s linear;
        z-index: 2;
    }

    .dropdown__container_with-top-space {
        top: 52px;
        left: (-$space-8);
    }

    .dropdown__container_right {
        right: 0;
    }

    .dropdown__container_open {
        visibility: visible;
        opacity: 1;
    }
</style>
