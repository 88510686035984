import store from '../store';

export async function getQuayFromStore(id) {
    if (store.getters.quays && store.getters.quays.length) {
        return store.getters.quays.find(quay => quay.URN === id);
    } else {
        await store.dispatch('getQuays');
        return store.getters.quays.find(quay => quay.URN === id);
    }
}
