export default async function formatLocation(location) {
    location.position = {
        lat: +location.position.latitude,
        lng: +location.position.longitude,
    };

    location.area.coordinates = location.area.coordinates.map(coordinate => ({
        lat: +coordinate.latitude,
        lng: +coordinate.longitude,
    }));

    return location;
}
