import portCallPCM from '@/services/port-call-manager/portCallPCM';
import formatPortcall from '@/helpers/formatPortcall.helper';
import formatStatement from '@/helpers/formatStatement.helper';
import formatEvent from '@/helpers/formatEvent.helper';
import addVesselToPortcall from '@/helpers/addVesselToPortcall.helper';

export const selectedPortCall = {
    state: {
        selectedPortCall: null,
        selectedPortCallStatements: null,
        selectedPortCallEvents: null,
    },
    getters: {
        selectedPortCall: state => {
            return state.selectedPortCall;
        },
        selectedPortCallId: state => {
            return state.selectedPortCall && state.selectedPortCall.portCallId;
        },
        selectedPortCallStatements: state => {
            return state.selectedPortCallStatements;
        },
        selectedPortCallEvents: state => {
            return state.selectedPortCallEvents;
        },
        vesselMMSI: state => {
            return state.selectedPortCall && state.selectedPortCall.vessel && state.selectedPortCall.vessel.mmsi;
        },
        vesselIMO: state => {
            return state.selectedPortCall && state.selectedPortCall.vessel && state.selectedPortCall.vessel.imo;
        },
    },
    mutations: {
        setSelectedPortCall(state, portCall) {
            state.selectedPortCall = portCall;
        },
        setSelectedPortCallStatements(state, statements) {
            state.selectedPortCallStatements = statements;
        },
        setSelectedPortCallEvents(state, events) {
            state.selectedPortCallEvents = events;
        },
    },
    actions: {
        async getPortCall({ commit }, id) {
            const response = await portCallPCM.show(id);
            const portcall = await addVesselToPortcall(formatPortcall(response.data));
            commit('setSelectedPortCall', portcall);
        },
        async getPortCallStatements({ commit }, id) {
            const response = await portCallPCM.statements(id);
            let statementsFromResponse = [...response.data.statements, ...response.data.carmentaStatements];
            let statements = await Promise.all(statementsFromResponse.map(formatStatement));
            statements = statements.sort((firstItem, secondItem) => {
                return !!firstItem.isWithdrawn - !!secondItem.isWithdrawn;
            });
            commit('setSelectedPortCallStatements', statements);
        },
        async getPortCallEvents({ commit }, id) {
            const response = await portCallPCM.events(id);
            let eventsFromResponse = [...response.data.events, ...response.data.carmentaEvents];
            const events = await Promise.all(eventsFromResponse.map(event => formatEvent(event, false)));
            commit('setSelectedPortCallEvents', events);
        },
    },
};
