import vesselVR from '@/services/vessel-registry/vesselVR';
import formatVessel from '@/helpers/formatVessel.helper';
import { memoize } from 'lodash';

const getVessel = memoize(async vesselId => {
    return await vesselVR.show(vesselId);
});

export default async function addVesselToPortcall(portcall) {
    const vesselResponse = await getVessel(portcall.vesselId);
    portcall.vessel = await formatVessel(vesselResponse.data);
    return portcall;
}
