// const EMPTY_STATUS = 'EMPTY';
const CRITICAL_STATUS = 'CRITICAL';
const WARNING_STATUS = 'WARNING';
const OK_STATUS = 'OK';

export default function formatStatus(serverStatus) {
    let status = 'empty';
    serverStatus === OK_STATUS && (status = 'check');
    serverStatus === CRITICAL_STATUS && (status = 'error');
    serverStatus === WARNING_STATUS && (status = 'warning');
    return status;
}
