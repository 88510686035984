import capitalize from '@/filters/capitalize.filter';
import getInfoAfterLastColon from '@/filters/getInfoAfterLastColon.filter';
import formatStatus from '@/helpers/formatStatus.helper';

export default function formatPortcall(portcall) {
    portcall.stage = portcall.stage
        .split('_')
        .map(value => capitalize(value.toLowerCase()))
        .join(' ');
    portcall.lastUpdatedState = portcall.lastUpdatedState
        ? portcall.lastUpdatedState
              .split('_')
              .map(value => capitalize(value.toLowerCase()))
              .join(' ')
        : '';
    portcall.lastUpdatedBy = getInfoAfterLastColon(portcall.lastUpdatedBy);
    portcall.timeType = portcall.timeType ? capitalize(portcall.timeType.toLowerCase()) : '–';
    portcall.status = formatStatus(portcall.status);
    portcall.vessel = {
        name: 'No Vessel Name',
    };

    return portcall;
}
