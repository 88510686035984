import { Api } from '@/services/apiService';
import transformRequestParams from '@/helpers/transformRequestParams.helper';

class eventPCM {
    static index(params) {
        return Api.get(`/pcb/event?${transformRequestParams(params)}`);
    }

    static show(id) {
        return Api.get(`/pcb/event/${id}`);
    }
}

export default eventPCM;
