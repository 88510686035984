import jwt_decode from 'jwt-decode';

export default function getUserByToken(token) {
    const decoded = jwt_decode(token);

    return {
        firstName: decoded.given_name,
        lastName: decoded.family_name,
        fullName: decoded.name,
        email: decoded.emails[0],
    };
}
