<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
    import Cookies from 'js-cookie';
    import { mapGetters } from 'vuex';
    import takeDataFromLocalStorage from '@/helpers/takeDataFromLocalStorage.js';

    export default {
        created() {
            const token = Cookies.get('USER_TOKEN');
            if (token) {
                this.$store.dispatch('login', JSON.parse(token));
            }
            this.takeDataFromLocalStorage();
        },
        computed: {
            ...mapGetters(['token']),
        },
        watch: {
            token: {
                handler(val) {
                    if (val) {
                        this.$route.name === 'login' && this.$router.push({ name: 'portcall' });
                    } else {
                        this.$route.name !== 'login' && this.$router.push({ name: 'login' });
                    }
                },
                immediate: false,
            },
        },
        methods: {
            takeDataFromLocalStorage,
        },
    };
</script>

<style lang="scss">
    @import 'assets/sass/style';
</style>
