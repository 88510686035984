import axios from 'axios';
import store from '../store';
import _ from 'lodash';

export const Api = axios.create({ baseURL: `/api` });

export function setApiToken({ tokenType, token }) {
    Api.defaults.headers.Authorization = `${tokenType} ${token}`;
}

Api.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        console.log(error);
        return Promise.reject(error);
    }
);

Api.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response && error.response.status === 403) {
            store.dispatch('logOut');
        }
        return Promise.reject(error);
    }
);

export function convertObjectKeys(object, func) {
    func = func || _.camelCase;
    if (!_.isObject(object)) {
        return object;
    }
    return _.mapValues(
        _.mapKeys(object, (value, key) => func(key)),
        value => {
            if (_.isObject(value)) {
                if (_.isArray(value)) {
                    return _.map(value, val => convertObjectKeys(val, func));
                } else {
                    return convertObjectKeys(value, func);
                }
            }
            return value;
        }
    );
}
