import vesselVR from '@/services/vessel-registry/vesselVR';
import formatVessel from '@/helpers/formatVessel.helper';

export default async function formatEventsToVisits(result, event) {
    if (!event.vesselId) return result;
    if (!event.startTime) event.startTime = 'Unknown';
    if (!event.endTime) event.endTime = 'Unknown';
    let status = 'Unknown';
    if (Date.now() < Date.parse(event.startTime)) {
        status = 'Planned';
    } else if (Date.now() - Date.parse(event.endTime) < 0) {
        status = 'Current';
    } else if (Date.now() > Date.parse(event.endTime)) {
        status = 'Passed';
    }
    if (!result[event.vesselId]) {
        let response = await vesselVR.show(event.vesselId);
        let vessel = await formatVessel(response.data);
        result[event.vesselId] = {
            ...vessel,
            numberOfPlanned: 0,
            numberOfCurrent: 0,
            numberOfPassed: 0,
            numberOfUnknown: 0,
            visits: [event],
        };
        result[event.vesselId][`numberOf${status}`] += 1;
    } else {
        result[event.vesselId].visits.push(event);
        result[event.vesselId][`numberOf${status}`] += 1;
    }

    return result;
}
