import portCallPCM from '@/services/port-call-manager/portCallPCM';
import formatPortcall from '@/helpers/formatPortcall.helper';
import addVesselToPortcall from '@/helpers/addVesselToPortcall.helper';
import localStorage from '@/services/local-storage/localStorage';

const DEFAULT_STAGES = () => [
    { name: 'Planned', checked: true },
    { name: 'Arrived', checked: true },
    { name: 'Anchored', checked: true },
    { name: 'Berthed', checked: true },
    { name: 'Under Way', checked: true },
    { name: 'Sailed', checked: true },
];

const DEFAULT_STATUSES = () => [
    { name: 'Ok', checked: true, color: 'green' },
    { name: 'Warning', checked: true, color: 'orange' },
    { name: 'Critical', checked: true, color: 'red' },
];

const DEFAULT_TIME_FILTER = () => {
    const addDays = (date, days) => {
        let result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    };
    const now = new Date();
    const prev = addDays(now, -6);
    const next = addDays(now, 8);
    return {
        filterByTime: false,
        after: prev,
        before: next,
        useInterval: false,
    };
};

const formatFilters = filters => {
    const formatOption = option => {
        return (
            option.checked &&
            option.name
                .split(' ')
                .map(value => value.toUpperCase())
                .join('_')
        );
    };
    let formattedFilters = {
        stage: filters.stage.map(formatOption).filter(value => !!value),
        status: filters.status.map(formatOption).filter(value => !!value),
        vessel_type: filters.vesselType
            .reduce((result, vesselTypes) => {
                return [...result, ...vesselTypes];
            }, [])
            .map(formatOption)
            .filter(value => !!value),
    };
    if (filters.vessel && filters.vessel.length) {
        formattedFilters.vessel = filters.vessel
            .filter(value => !!value.checked)
            .reduce((result, value) => {
                result = [...result, ...value.vessels.map(vessel => vessel.imo)];
                return result;
            }, []);
    }
    if (filters.quay && filters.quay.length) {
        formattedFilters.location = filters.quay
            .filter(value => !!value.checked)
            .reduce((result, value) => {
                result = [...result, ...value.quays.map(quay => quay.URN)];
                return result;
            }, []);
    }
    if (filters.time.filterByTime) {
        formattedFilters.after = filters.time.after.toISOString();
        formattedFilters.before = new Date(new Date(filters.time.before).setHours(23, 59, 59, 999)).toISOString();
    }
    return formattedFilters;
};

const AMOUNT_OF_ELEMENTS_IN_CHAIN = 50;

const getNextChainOfPortCallsWithVessel = async (portCalls, chainNumber) => {
    return [
        ...portCalls.slice(0, AMOUNT_OF_ELEMENTS_IN_CHAIN * chainNumber),
        ...(await Promise.all(
            portCalls
                .slice(AMOUNT_OF_ELEMENTS_IN_CHAIN * chainNumber, AMOUNT_OF_ELEMENTS_IN_CHAIN * (chainNumber + 1))
                .map(addVesselToPortcall)
        )),
        ...portCalls.slice(AMOUNT_OF_ELEMENTS_IN_CHAIN * (chainNumber + 1)),
    ];
};

export const portCalls = {
    state: {
        portCalls: [],
        filters: {
            stage: DEFAULT_STAGES(),
            status: DEFAULT_STATUSES(),
            time: DEFAULT_TIME_FILTER(),
            vesselType: [],
            vessel: [],
            quay: [],
        },
    },
    getters: {
        portCalls: state => {
            return state.portCalls;
        },
        portcallFilters: state => {
            return state.filters;
        },
    },
    mutations: {
        setPortCalls(state, portCalls) {
            state.portCalls = portCalls;
        },
        setDefaultsPortCallsFilters(state) {
            state.filters = Object.assign(
                {},
                {
                    stage: DEFAULT_STAGES(),
                    status: DEFAULT_STATUSES(),
                    time: DEFAULT_TIME_FILTER(),
                    vesselType: [],
                    vessel: [],
                    quay: [],
                }
            );
            localStorage.create('filtersParams', state.filters);
        },
        setPortCallsFilters(state, filters) {
            localStorage.create('filtersParams', filters);
            state.filters = filters;
        },
        addPortCall(state, portCall) {
            state.portCalls.push(portCall);
        },
        addListsToFilters(state, { type, lists }) {
            state.filters[type] = lists;
            localStorage.create('filtersParams', state.filters);
        },
        removeListsFromFilters(state, { type, lists }) {
            state.filters[type] = lists;
            localStorage.create('filtersParams', state.filters);
        },
    },
    actions: {
        async getPortCalls({ commit, state }) {
            const response = await portCallPCM.index(formatFilters(state.filters));
            let portCalls = response.data.map(formatPortcall);
            commit('setPortCalls', portCalls);
            const amountOfChains = portCalls.length / AMOUNT_OF_ELEMENTS_IN_CHAIN;
            for (let chainNumber = 0; chainNumber < amountOfChains; chainNumber++) {
                portCalls = await getNextChainOfPortCallsWithVessel(portCalls, chainNumber);
                commit('setPortCalls', portCalls);
            }
        },
    },
};
