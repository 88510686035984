import vesselVR from '@/services/vessel-registry/vesselVR';
import formatVessel from '@/helpers/formatVessel.helper';
import formatStatement from '@/helpers/formatStatement.helper';
import { getQuayFromStore } from '@/helpers/getQuayFromState.helper';
import { memoize } from 'lodash';
const MILLISECONDS_IN_MINUTE = 60000;
const MILLISECONDS_IN_DAY_HALF = MILLISECONDS_IN_MINUTE * 60 * 12;

const getVessel = memoize(async vesselId => {
    return await vesselVR.show(vesselId);
});

export default async function formatEvent(event, shouldSimulateTime = false) {
    if (event.startTime) {
        event.startTime = Date.parse(event.startTime);
    } else if (shouldSimulateTime) {
        event.startTime = Date.parse(event.endTime) - MILLISECONDS_IN_DAY_HALF;
        event.hasSimulatedArrivalTime = true;
    }
    if (event.endTime) {
        event.endTime = Date.parse(event.endTime);
    } else if (shouldSimulateTime) {
        event.endTime = Date.parse(event.startTime) + MILLISECONDS_IN_DAY_HALF;
        event.hasSimulatedDepartureTime = true;
    }
    if (event.at) {
        event.at = await getQuayFromStore(event.at);
    }

    if (event.from) {
        event.from = await getQuayFromStore(event.from);
    }

    if (event.to) {
        event.to = await getQuayFromStore(event.to);
    }
    if (event.vesselId) {
        const response = await getVessel(event.vesselId);
        event.vessel = await formatVessel(response.data);
    }
    if (event.statements) {
        event.statements = await Promise.all(event.statements.map(formatStatement));
    }

    event.isSimulatedTime = shouldSimulateTime;

    return event;
}
