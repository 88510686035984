import locationLR from '@/services/location-registry/locationLR';
import formatLocation from '@/helpers/formatLocation.helper';
import { throttle } from 'lodash';
import eventPCM from '@/services/port-call-manager/eventPCM';
import formatEvent from '@/helpers/formatEvent.helper';
import getStatementsForQuaysEvents from '@/helpers/getStatementsForQuaysEvents.helper.js';
import { getQuayUnlocode } from '@/helpers/getQuayUnlocode.helper';

const duplicateQuayURNWithoutCoordinates = 'urn:mrn:stm:location:nosvg:tug_zone:tug cruiseship';

export const quays = {
    state: {
        quays: [],
        quaysEvents: [],
    },
    getters: {
        quays: state => {
            return state.quays;
        },
        quaysNames: state => {
            return state.quays.map(quay => {
                return {
                    URN: quay.URN,
                    name: quay.name,
                };
            });
        },
        quaysEvents: state => {
            return state.quaysEvents;
        },
    },
    mutations: {
        setQuays(state, quays) {
            quays.forEach(quay => {
                let unlocode = getQuayUnlocode(quay);
                quay.name = quay.locationType
                    ? unlocode + ':' + quay.locationType + ':' + quay.name
                    : unlocode + ':' + quay.name;
            });
            state.quays = quays;
        },
        setQuaysFromCustomLists(state, options) {
            state.quays = options;
        },
        setSelectedQuaysEvents(state, events) {
            state.quaysEvents = events;
        },
    },
    actions: {
        getQuays: throttle(async ({ commit }) => {
            const response = await locationLR.index();
            response.data = await response.data.filter(quay => {
                return quay.URN !== duplicateQuayURNWithoutCoordinates || !quay.area.coordinates.length;
            });
            const quays = await Promise.all(response.data.map(formatLocation));
            commit('setQuays', quays);
        }, 1000),
        setQuaysFromCustomLists({ commit }, options) {
            if (options && options.length) {
                options = options.filter(item => item.checked).flatMap(option => option.quays);
                commit('setQuaysFromCustomLists', options);
            }
        },
        async getSelectedQuaysEvents({ commit }, params) {
            const response = await eventPCM.index(params);
            let events = await Promise.all(response.data.map(event => formatEvent(event, true)));
            events = await getStatementsForQuaysEvents(events);

            commit('setSelectedQuaysEvents', events);
        },
    },
};
