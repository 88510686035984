import Vue from 'vue';
import VueRouter from 'vue-router';
import Cookies from 'js-cookie';

const AuthLayout = () => import('@/layouts/AuthLayout.vue');
const DefaultLayout = () => import('@/layouts/DefaultLayout.vue');

const Login = () => import('@/views/Login.vue');
const PortcallList = () => import('@/components/port-call-components/PortcallList');
const PortcallPreview = () => import('@/views/PortcallPreview');
const QuayList = () => import('@/components/quay-components/QuayList');
const QuayPreview = () => import('@/views/QuayPreview');
const PortPreview = () => import('@/views/PortPreview');
const CustomPreview = () => import('@/views/CustomPreview');
const About = () => import('@/views/About');
const MyLists = () => import('@/views/MyLists');
const CustomSidebar = () => import('@/components/CustomSidebar');

Vue.use(VueRouter);

const isUserAuthenticated = (to, from, next) => {
    return Cookies.get('USER_TOKEN') ? next() : next({ name: 'login' });
};

const routes = [
    {
        path: '/auth',
        component: AuthLayout,
        children: [
            {
                path: '',
                name: 'login',
                component: Login,
            },
            {
                path: 'callback',
                name: 'callback',
                component: Login,
            },
        ],
    },
    {
        path: '/',
        component: DefaultLayout,
        beforeEnter: isUserAuthenticated,
        children: [
            {
                path: '/portcall/:id?',
                name: 'portcall',
                components: {
                    sidebar: PortcallList,
                    default: PortcallPreview,
                },
            },
            {
                path: '/quay/:id?',
                name: 'quay',
                components: {
                    sidebar: QuayList,
                    default: QuayPreview,
                },
            },
            {
                path: '/port',
                name: 'port',
                component: PortPreview,
            },
            {
                path: '/custom',
                name: 'custom',
                components: {
                    sidebar: CustomSidebar,
                    default: CustomPreview,
                },
            },
            {
                path: '/about',
                name: 'about',
                component: About,
            },
            {
                path: '/my-lists',
                name: 'my-lists',
                component: MyLists,
            },
        ],
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
