<template>
    <label class="input-container">
        <div v-if="isSearch" class="input-container__icon" @click="search">
            <mf-icon icon-name="icon-search" width="20" height="20" />
        </div>
        <input
            class="mf-input"
            :value="value"
            @input="input"
            :disabled="disabled"
            :error="error"
            :type="type"
            :placeholder="placeholder"
            @focus="focus"
            :readonly="!typeable"
            :class="inputClasses"
        />
        <div v-if="isDatepicker" class="input-container__icon input-container__icon_date" @click="date">
            <mf-icon icon-name="icon-calendar" width="20" height="20" />
        </div>
        <div v-if="withClock" class="input-container__icon input-container__icon_date">
            <mf-icon icon-name="icon-clock" width="20" height="20" />
        </div>
    </label>
</template>

<script>
    export default {
        props: {
            value: {
                type: [String, Number, Boolean],
                default: null,
            },
            type: {
                type: String,
                default: 'text',
                validator: value => {
                    return value.match(/(text|number|email|password|tel|hidden)/);
                },
            },
            placeholder: {
                type: String,
                default: '',
            },
            error: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            isSearch: {
                type: Boolean,
                default: false,
            },
            isDatepicker: {
                type: Boolean,
                default: false,
            },
            withClock: {
                type: Boolean,
                default: false,
            },
            typeable: {
                type: Boolean,
                default: true,
            },
        },
        methods: {
            input(event) {
                this.$emit('input', event.target.value);
            },
            focus(event) {
                this.$emit('focus', event);
            },
            search(event) {
                this.$emit('search', event);
            },
            date(event) {
                this.$emit('date', event);
            },
        },
        computed: {
            inputClasses() {
                return {
                    'mf-input_error': this.error,
                    'mf-input_disabled': this.disabled,
                    'mf-input_search': this.isSearch,
                    'mf-input_date': this.isDatepicker,
                };
            },
        },
    };
</script>

<style lang="scss" scoped>
    $icon-size: 20px;

    .input-container {
        position: relative;
        width: 100%;
    }

    .input-container__icon {
        position: absolute;
        cursor: pointer;
        height: 100%;

        padding: 0 $space-16;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $marine-300;
    }

    .input-container__icon_date {
        top: 0;
        right: 0;
    }

    .mf-input {
        outline: none;
        background: $marine-600;
        border-radius: $border-radius-6;
        width: 100%;
        border: 1px solid transparent;
        box-shadow: none;

        padding: $space-12 $space-16;

        font-weight: normal;
        font-size: $font-16;
        line-height: $font-24;

        color: $marine-300;
        &::placeholder {
            color: $marine-400;
        }
        &:hover,
        &:focus,
        &:active {
            border: 1px solid $marine-500;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }
    }

    .mf-input_search {
        padding-left: $space-32 + $icon-size;
    }

    .mf-input_date {
        padding-right: $space-32 + $icon-size;
    }

    .mf-input_disabled {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.6;
        &:hover,
        &:focus,
        &:active {
            border: 1px solid transparent;
        }
    }

    .mf-input_error {
        color: $red-500;
        border-color: rgba($red-500, 0.6);
        &:hover,
        &:focus,
        &:active {
            border: 1px solid $red-500;
        }
    }
</style>
