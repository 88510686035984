import localStorage from '@/services/local-storage/localStorage.js';

export default function takeDataFromLocalStorage() {
    let filtersParams = localStorage.show('filtersParams');
    if (filtersParams) {
        if (filtersParams.time) {
            filtersParams.time.after = new Date(filtersParams.time.after);
            filtersParams.time.before = new Date(filtersParams.time.before);
        }
        this.$store.commit('setPortCallsFilters', filtersParams);
    }

    const favouritePortcalls = localStorage.show('favouritePortcalls');
    if (favouritePortcalls && favouritePortcalls.portcalls) {
        this.$store.commit('addFavouritesPortcalls', favouritePortcalls.portcalls);
        this.$store.commit('setIsFavouritePortcalls', favouritePortcalls.checked);
    }

    const customLists = localStorage.show('customLists');
    if (customLists) {
        if (customLists.quay && customLists.quay.length) {
            this.$store.dispatch('addCustomLists', customLists.quay);
        }
        if (customLists.vessel && customLists.vessel.length) {
            this.$store.dispatch('addCustomLists', customLists.vessel);
        }
    }

    const favouriteQuays = localStorage.show('favouriteQuays');
    if (favouriteQuays) {
        this.$store.commit('addFavoriteQuays', favouriteQuays);
    }

    const recentStates = localStorage.show('recentStates');
    if (recentStates) {
        this.$store.commit('addRecentStates', recentStates);
    }
}
