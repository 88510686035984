<template>
    <button
        class="mf-button"
        :type="type"
        @click="$emit('click', $event)"
        :disabled="disabled"
        :to="to"
        :class="buttonClasses"
    >
        <div class="mf-button__icon" :class="{ 'mf-button__icon_last': iconLast }" v-if="$slots['icon']">
            <slot name="icon" />
        </div>
        <slot />
    </button>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: 'base',
                validator: value => value.match(/(xx-small|x-small|small|base)/),
            },
            pattern: {
                type: String,
                default: 'primary',
                validator: value => value.match(/(primary|primary-outline)/),
            },
            color: {
                type: String,
                default: 'marine',
                validator: value => value.match(/(marine|blue|green)/),
            },
            type: {
                type: String,
                default: 'submit',
                validator: value => value.match(/(button|reset|submit)/),
            },
            to: {
                type: Object,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            iconLast: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            buttonClasses() {
                return [
                    `mf-button_size-${this.size}`,
                    `mf-button_${this.pattern}-${this.color}`,
                    this.disabled && 'mf-button_disabled',
                ];
            },
        },
    };
</script>

<style lang="scss" scoped>
    .mf-button {
        display: flex;
        justify-content: center;

        border-radius: $border-radius-6;
        border: 1px solid transparent;
        color: $white;

        font-weight: normal;

        font-size: $font-16;
        line-height: $font-24;
    }

    .mf-button__icon {
        padding-right: $space-8;
    }

    .mf-button__icon_last {
        padding: 0 0 0 $space-8;
        order: 2;
    }

    .mf-button_size-base {
        padding: $space-12 $space-16;
    }

    .mf-button_size-small {
        padding: $space-8 $space-16;
    }

    .mf-button_size-x-small {
        padding: $space-8 $space-16;
        font-size: $font-14;
        line-height: $font-16;
    }

    .mf-button_size-xx-small {
        padding: $space-4 $space-16;
        font-size: $font-14;
        line-height: $font-16;
    }

    .mf-button_primary-marine {
        color: $marine-300;
        background: $marine-600;
        &:hover {
            color: $white;
            background: $marine-500;
        }
    }

    .mf-button_primary-blue {
        background: $blue-500;
        &:hover {
            background: $blue-300;
        }
    }

    .mf-button_primary-green {
        background: $green-700;
        &:hover {
            background: $green-500;
        }
    }

    .mf-button_primary-outline-marine {
        border-color: $marine-400;
        background: transparent;
        &:hover {
            background: transparent;
            border-color: $white;
        }
    }

    .mf-button_primary-outline-blue {
        border-color: $blue-300;
        color: $blue-300;
        background: transparent;
        &:hover {
            background: $blue-500;
        }
    }

    .mf-button_primary-outline-green {
        border-color: $green-500;
        color: $green-500;
        background: transparent;
        &:hover {
            background: $green-700;
        }
    }

    .mf-button_disabled {
        cursor: default;
        opacity: 0.6;
        pointer-events: none;
    }
</style>
