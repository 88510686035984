<template>
    <transition name="fade">
        <div class="modal">
            <div class="modal__background" @click="close" />
            <mf-box class="modal__container" :class="{ modal__container_small: small }">
                <div slot="header">
                    <div class="modal__header">
                        <slot name="header" />
                        <a class="modal__close" href="javascript://" @click="close">
                            <mf-icon icon-name="icon-close" />
                        </a>
                    </div>
                </div>
                <div :class="{ modal__scrollable: scrollable }">
                    <slot />
                </div>
                <div slot="footer">
                    <slot name="footer" />
                </div>
            </mf-box>
        </div>
    </transition>
</template>

<script>
    export default {
        name: 'MfModal',
        props: {
            small: {
                type: Boolean,
                default: false,
            },
            scrollable: {
                type: Boolean,
                default: true,
            },
        },
        mounted() {
            document.documentElement.style.setProperty('overflow', 'hidden');
        },
        methods: {
            close() {
                this.$emit('close');
            },
        },
        beforeDestroy() {
            document.documentElement.style.setProperty('overflow', 'auto');
        },
    };
</script>

<style lang="scss" scoped>
    .modal {
        z-index: 3;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 $space-8;
        @include media($sm) {
            padding: 0 $space-16;
        }
    }

    .modal__background {
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba($marine-900, 0.8);
    }

    .modal__container {
        z-index: 4;
        width: 100%;
        max-width: 1046px;
        max-height: 90vh;
    }

    .modal__container_small {
        width: 100%;
        max-width: 560px;
    }

    .modal__header {
        display: flex;
        justify-content: space-between;
    }

    .modal__scrollable {
        max-height: calc(90vh - 120px - 70px);
        overflow-y: auto;
        overflow-x: hidden;
        @include scrollbar();
    }

    .modal__close {
        color: $marine-400;
        &:hover {
            color: $white;
        }
    }

    @import '@/assets/sass/transitions/fade';
</style>
