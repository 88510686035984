<template>
    <div
        :class="[calendarClass, 'mf-datepicker__calendar']"
        v-show="showDayView"
        :style="calendarStyle"
        @mousedown.prevent
    >
        <slot name="beforeCalendarHeader"></slot>
        <header>
            <span @click="isRtl ? nextMonth() : previousMonth()" class="prev" :class="{ disabled: isLeftNavDisabled }">
                <mf-icon icon-name="icon-left" width="12" height="12" />
            </span>
            <span class="day__month_btn" @click="showMonthCalendar" :class="allowedToShowView('month') ? 'up' : ''">
                {{ currMonthName }}
            </span>
            <span @click="isRtl ? previousMonth() : nextMonth()" class="next" :class="{ disabled: isRightNavDisabled }">
                <mf-icon icon-name="icon-right" width="12" height="12" />
            </span>
        </header>
        <div class="main-area" :class="isRtl ? 'flex-rtl' : ''">
            <span class="cell-container" v-for="d in daysOfWeek" :key="d.timestamp">
                <span class="cell day-header">
                    {{ d }}
                </span>
            </span>
            <template v-if="blankDays > 0">
                <span class="cell-container" v-for="d in blankDays" :key="d.timestamp">
                    <span class="cell day blank">
                        {{ daysInPreviousMonth - blankDays + d }}
                    </span>
                </span>
            </template>
            <span class="cell-container" v-for="day in days" :key="day.timestamp">
                <span
                    class="cell day"
                    :class="dayClasses(day)"
                    v-html="dayCellContent(day)"
                    @click="selectDate(day)"
                ></span>
            </span>
            <template v-if="blankDaysInNextMonth > 0">
                <span class="cell-container" v-for="d in blankDaysInNextMonth" :key="d.timestamp">
                    <span class="cell day blank">
                        {{ d }}
                    </span>
                </span>
            </template>
        </div>
    </div>
</template>
<script>
    import PickerDay from 'vuejs-datepicker/src/components/PickerDay';
    export default {
        extends: PickerDay,
        computed: {
            blankDaysInNextMonth() {
                const d = this.pageDate;
                let dObj = this.useUtc
                    ? new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth() + 1, 1))
                    : new Date(d.getFullYear(), d.getMonth() + 1, 1, d.getHours(), d.getMinutes());
                return this.utils.getDay(dObj) > 0 ? 7 - this.utils.getDay(dObj) : 0;
            },
            daysInPreviousMonth() {
                const currentMonthDate = new Date(this.days[0].timestamp);
                const dObj = new Date(
                    currentMonthDate.getFullYear(),
                    currentMonthDate.getMonth() - 1,
                    currentMonthDate.getDate()
                );
                return this.utils.daysInMonth(this.utils.getFullYear(dObj), this.utils.getMonth(dObj));
            },
        },
    };
</script>
