const DEFAULT_PORTCALL_ELEMENTS = () => [
    { name: 'Portcall List', checked: true },
    { name: 'Portcall Locations', checked: true },
    { name: 'Portcall Multiview', checked: true },
    { name: 'Portcall States', checked: true },
    { name: 'Portcall Timeline', checked: true },
    { name: 'Portcall Demurrage Timeline', checked: true },
    { name: 'Port To Port', checked: true },
    { name: 'Stage Completion', checked: true },
    { name: 'Statement Log', checked: true },
    { name: 'Time Progress', checked: true },
    { name: 'Vessel Tracker', checked: true },
    { name: 'Vessel Traffic', checked: true },
    { name: 'Chat', checked: true },
];

const DEFAULT_QUAY_ELEMENTS = () => [
    { name: 'Quay List', checked: true },
    { name: 'Quay Location', checked: true },
    { name: 'Quay Multiview Timeline', checked: true },
    { name: 'Quay Status', checked: true },
    { name: 'Quay Timeline', checked: true },
];

const DEFAULT_PORT_ELEMENTS = () => [
    { name: 'Port Overall Workload', checked: true },
    { name: 'Port Overall Stage', checked: true },
    { name: 'Port Overall Status', checked: true },
];

const DEFAULT_ADDITION_ELEMENTS = () => [{ name: 'Viva Weather', checked: true }];

export const customLayout = {
    state: {
        layout: {
            portcall: DEFAULT_PORTCALL_ELEMENTS(),
            quay: DEFAULT_QUAY_ELEMENTS(),
            port: DEFAULT_PORT_ELEMENTS(),
            addition: DEFAULT_ADDITION_ELEMENTS(),
        },
    },
    getters: {
        customLayout: state => {
            return state.layout;
        },
    },
    mutations: {
        setCustomLayout(state, layout) {
            state.layout = layout;
        },
        setDefaultsCustomLayout(state) {
            state.layout = {
                portcall: DEFAULT_PORTCALL_ELEMENTS(),
                quay: DEFAULT_QUAY_ELEMENTS(),
                port: DEFAULT_PORT_ELEMENTS(),
                addition: DEFAULT_ADDITION_ELEMENTS(),
            };
        },
    },
};
