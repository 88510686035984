export default function dateFilter(value, format = 'date') {
    const options = {};

    if (format.includes('portCall')) {
        options.day = 'numeric';
        options.month = 'short';
        options.hour = '2-digit';
        options.minute = '2-digit';
        options.hour12 = false;
    }

    if (format.includes('statistic')) {
        options.weekday = 'long';
        options.day = 'numeric';
        options.month = 'short';
        options.hour12 = false;
    }

    if (format.includes('location')) {
        options.day = 'numeric';
        options.month = 'short';
        options.hour = '2-digit';
        options.minute = '2-digit';
        options.hour12 = false;
    }

    if (format.includes('date')) {
        options.day = '2-digit';
        options.month = '2-digit';
        options.year = 'numeric';
    }

    if (format.includes('short-year')) {
        options.year = '2-digit';
    }

    if (format.includes('time')) {
        options.hour = '2-digit';
        options.minute = '2-digit';
        options.hour12 = false;
    }

    return new Intl.DateTimeFormat('en-EN', options).format(new Date(value));
}
