import capitalize from '@/filters/capitalize.filter';
import getInfoAfterLastColon from '@/filters/getInfoAfterLastColon.filter';

export default async function formatVessel(vessel) {
    vessel.name = vessel.name
        .split(' ')
        .map(value => capitalize(value.toLowerCase()))
        .join(' ');
    vessel.imo = vessel.imo ? getInfoAfterLastColon(vessel.imo) : '';
    vessel.mmsi = vessel.mmsi ? getInfoAfterLastColon(vessel.mmsi) : '';
    vessel.vesselType = vessel.vesselType
        ? vessel.vesselType
              .split('_')
              .map(value => capitalize(value.toLowerCase()))
              .join(' ')
        : '';
    return vessel;
}
