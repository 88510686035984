<template>
    <div :class="{ 'input-group': bootstrapStyling }">
        <!-- Input -->
        <mf-input
            :type="inline ? 'hidden' : 'text'"
            :class="computedInputClass"
            :name="name"
            :ref="refName"
            :id="id"
            :value="formattedValue"
            :open-date="openDate"
            :placeholder="placeholder"
            :clear-button="clearButton"
            :disabled="disabled"
            :required="required"
            :typeable="typeable"
            @click.native="showCalendar"
            @date="showCalendar"
            @keyup.native="parseTypedDate"
            @blur.native="inputBlurred"
            autocomplete="off"
            is-datepicker
        />
    </div>
</template>
<script>
    import DateInput from 'vuejs-datepicker/src/components/DateInput';
    export default {
        extends: DateInput,
    };
</script>
