<template>
    <label class="mf-checkbox" :class="{ 'mf-checkbox_disabled': disabled, 'mf-checkbox_checked': checked }">
        <input class="mf-checkbox__input" type="checkbox" @change="input" :checked="checked" />
        <div class="mf-checkbox__box">
            <mf-icon class="mf-checkbox__icon" icon-name="icon-check" />
        </div>
        <div class="mf-checkbox__text">
            <slot />
        </div>
    </label>
</template>

<script>
    export default {
        name: 'MfCheckbox',
        props: {
            checked: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        methods: {
            input(event) {
                this.$emit('change', event.target.checked);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .mf-checkbox {
        color: $white;
        display: flex;
        cursor: pointer;

        &:hover {
            .mf-checkbox__box {
                border-color: $blue-300;
            }
        }
    }

    .mf-checkbox__input {
        display: none;
    }

    .mf-checkbox__box {
        width: 28px;
        height: 28px;
        border: 2px solid $marine-400;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px;
    }

    .mf-checkbox__icon {
        display: none;
    }

    .mf-checkbox__text {
        display: flex;
        align-items: center;
        padding-left: 10px;
        flex: 1 1 auto;
    }

    .mf-checkbox_checked {
        .mf-checkbox__box {
            background: $blue-300;
            border-color: $blue-300;
        }

        .mf-checkbox__icon {
            display: block;
        }
    }

    .mf-checkbox_disabled {
        pointer-events: none;

        .mf-checkbox__box {
            border-color: $marine-700;
            background: $marine-700;
        }

        .mf-checkbox__text {
            color: $marine-400;
        }
    }
</style>
