export default function formatPortcallStateDefinitions(definitions) {
    const stateCategoriesWithServiceParameters = ['AdministrationState', 'ServiceState'];
    const stateCategoriesWithLocationParameters = ['LocationState'];

    definitions.forEach(definition => {
        if (stateCategoriesWithServiceParameters.includes(definition.stateType)) {
            definition['serviceType'] = definition['type'];
            definition['serviceObject'] = definition['object'];
        } else if (stateCategoriesWithLocationParameters.includes(definition.stateType)) {
            definition['locationType'] = definition['type'];
            definition['referenceObject'] = definition['object'];
        }

        //renamed for Columbia!
        if (definition.name === 'Pilot Vessel Departed') {
            definition.name = 'Departure Pilot Vessel';
        }

        delete definition['type'];
        delete definition['object'];
    });

    return definitions;
}
