<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        viewBox="0 0 50 50"
        :aria-labelledby="iconName"
        role="presentation"
    >
        <g :fill="iconColor">
            <component :is="icon" />
        </g>
    </svg>
</template>

<script>
    import { camelCase } from 'lodash';
    import capitalize from '@/filters/capitalize.filter';

    export default {
        props: {
            iconName: {
                type: String,
                required: true,
            },
            width: {
                type: [Number, String],
                default: 18,
            },
            height: {
                type: [Number, String],
                default: 18,
            },
            iconColor: {
                type: String,
                default: 'currentColor',
            },
        },
        computed: {
            icon() {
                const iconComponent = capitalize(camelCase(this.iconName));
                return () => import(`@/components/icons/${iconComponent}.vue`);
            },
        },
    };
</script>

<style scoped>
    svg {
        display: inline-block;
        vertical-align: baseline;
        margin-bottom: -2px;
    }
</style>
