<template>
    <div class="box" :class="{ box_bordered: borderBox, box_empty: withoutInnerPaddings && !empty }">
        <div class="box__header" v-if="!withoutHeader">
            <slot name="header" />
        </div>
        <div class="box__content" v-if="!empty">
            <slot />
        </div>
        <div v-else class="box__content">
            <slot name="empty">
                <div class="box__content_empty">
                    <slot name="empty-text" />
                </div>
            </slot>
        </div>
        <div class="box__footer" :class="{ box__footer_content: $slots['footer'] }">
            <slot name="footer" />
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            borderBox: {
                type: Boolean,
                default: false,
            },
            withoutInnerPaddings: {
                type: Boolean,
                default: false,
            },
            withoutHeader: {
                type: Boolean,
                default: false,
            },
            empty: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>

<style lang="scss" scoped>
    .box {
        background: $marine-800;
        border-radius: 6px;
    }

    .box__header {
        padding: $space-20 $space-16;
        font-weight: bold;
        font-size: $font-22;
        line-height: $font-30;
        color: $white;
        background: $marine-700;
        border-radius: 6px 6px 0 0;
    }

    .box__content {
        padding: $space-20 $space-16 0;
    }

    .box__content_empty {
        margin-top: $space-50;
        text-align: center;
        font-size: $font-16;
        line-height: $font-20;
        color: $marine-400;
        min-height: 100px;
    }

    .box__footer {
        padding-bottom: $space-16;
        border-radius: 0 0 6px 6px;
    }

    .box__footer_content {
        padding: $space-16;
    }

    .box_bordered {
        border: 1px solid $marine-500;

        .box__header {
            padding: $space-14 $space-16;
        }
    }

    .box_empty {
        .box__content {
            padding: 0;
        }

        .box__footer {
            padding: 0;
        }
    }
</style>
