import portCallPCM from '@/services/port-call-manager/portCallPCM';
import formatPortStatistic from '@/helpers/formatPortStatistic.helper';

const formatFilters = filterTime => {
    let formattedFilters = {
        after: '',
        before: '',
    };
    if (filterTime && filterTime.after && filterTime.before) {
        formattedFilters.after = filterTime.after.toISOString();
        formattedFilters.before = new Date(new Date(filterTime.before).setHours(23, 59, 59, 999)).toISOString();
    }
    return formattedFilters;
};

export const portStatistic = {
    state: {
        portStatistic: null,
    },
    getters: {
        portStatistic: state => {
            return state.portStatistic;
        },
    },
    mutations: {
        setPortStatistic(state, statistic) {
            state.portStatistic = statistic;
        },
    },
    actions: {
        async getPortStatistic({ commit }, filterTime) {
            const response = await portCallPCM.index(formatFilters(filterTime));
            const statistic = await formatPortStatistic(response.data, filterTime);
            commit('setPortStatistic', statistic);
        },
    },
};
