import capitalize from '@/filters/capitalize.filter';
import getInfoAfterLastColon from '@/filters/getInfoAfterLastColon.filter';

export default async function formatStatement(statement) {
    statement.stateDefinition = statement.stateDefinition.split('_').join(' ');
    statement.reportedBy = statement.reportedBy ? getInfoAfterLastColon(statement.reportedBy) : statement.reportedBy;
    statement.at = statement.at
        ? getInfoAfterLastColon(statement.at)
              .split('_')
              .map(capitalize)
              .join(' ')
        : '';
    statement.from = statement.from
        ? getInfoAfterLastColon(statement.from)
              .split('_')
              .map(capitalize)
              .join(' ')
        : '';
    statement.to = statement.to
        ? getInfoAfterLastColon(statement.to)
              .split('_')
              .map(capitalize)
              .join(' ')
        : '';
    statement.timeType = statement.timeType ? capitalize(statement.timeType.toLowerCase()) : '–';

    if (statement.stateDefinition === 'Pilot Vessel Departed') {
        statement.stateDefinition = 'Departure Pilot Vessel';
    }
    return statement;
}
