import portCallPCM from '@/services/port-call-manager/portCallPCM';
import formatEvent from '@/helpers/formatEvent.helper';

export default async function getStatementsForQuaysEvents(events) {
    for (const quayEvent of events) {
        const response = await portCallPCM.events(quayEvent.portCallId);
        let eventsFromResponse = [...response.data.events, ...response.data.carmentaEvents];
        let portCallEvents = await Promise.all(eventsFromResponse.map(formatEvent));

        quayEvent.statements = portCallEvents.find(event => {
            return event.eventId === quayEvent.eventId;
        }).statements;
    }

    return events;
}
