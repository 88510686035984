import { Api } from '@/services/apiService';
import transformRequestParams from '@/helpers/transformRequestParams.helper';

class portCallPCM {
    static index(params) {
        return Api.get(`/pcb/port_call?${transformRequestParams(params)}`);
    }

    static show(id) {
        return Api.get(`/pcb/port_call/${id}`);
    }

    static events(id) {
        return Api.post('events', { portcall_id: id }, { baseURL: '/new-api' });
    }

    static processes(id) {
        return Api.get(`/pcb/port_call/${id}/processes`);
    }

    static statements(id) {
        return Api.get(`/portcall/${id}/statements`, { baseURL: '/new-api' });
    }
}

export default portCallPCM;
