import locationLR from '@/services/location-registry/locationLR';
import formatLocation from '@/helpers/formatLocation.helper';
import eventPCM from '@/services/port-call-manager/eventPCM';
import formatEvent from '@/helpers/formatEvent.helper';
import formatEventsToVisits from '@/helpers/formatEventsToVisits';
import getStatementsForQuaysEvents from '@/helpers/getStatementsForQuaysEvents.helper';

export const selectedQuay = {
    state: {
        selectedQuay: null,
        selectedQuayEvents: null,
        selectedQuayVisitsInfo: null,
    },
    getters: {
        selectedQuay: state => {
            return state.selectedQuay;
        },
        selectedQuayId: state => {
            return state.selectedQuay && state.selectedQuay.URN;
        },
        selectedQuayEvents: state => {
            return state.selectedQuayEvents;
        },
        selectedQuayVisitsInfo: state => {
            return state.selectedQuayVisitsInfo;
        },
    },
    mutations: {
        setSelectedQuay(state, quay) {
            state.selectedQuay = quay;
        },
        setSelectedQuayEvents(state, events) {
            state.selectedQuayEvents = events;
        },
        setSelectedQuayVisitsInfo(state, visitsInfo) {
            state.selectedQuayVisitsInfo = visitsInfo;
        },
    },
    actions: {
        async getQuay({ commit }, id) {
            const response = await locationLR.show(id);
            const quay = await formatLocation(response.data);
            commit('setSelectedQuay', quay);
        },
        async getQuayEvents({ commit }, params) {
            const response = await eventPCM.index(params);
            let events = await Promise.all(response.data.map(event => formatEvent(event, true)));
            events = await getStatementsForQuaysEvents(events);

            commit('setSelectedQuayEvents', events);
        },
        async getQuayVisitsInfo({ commit }, params) {
            const response = await eventPCM.index(params);
            let visitsInfo = {};
            for (let event of response.data) {
                visitsInfo = await formatEventsToVisits(visitsInfo, event);
            }
            commit('setSelectedQuayVisitsInfo', visitsInfo);
        },
    },
};
